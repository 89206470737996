import Link from "next/link.js";
import { GitHubIcon, DiscordIcon } from "./Icons";
import { SocialLink, SocialPlatform } from "./types";

interface Props {
  links: Array<SocialLink>;
}

const icons: { [K in SocialPlatform]: React.FC<any> } = {
  github: GitHubIcon,
  discord: DiscordIcon,
};

export const NavSocial: React.FC<Props> = ({ links }) => {
  return (
    <>
      {links.map(({ label, href }) => {
        const Icon = icons[label];
        return (
          <div key={label} className="my-1 gap-2 flex  hover:!cursor-pointer ">

            <Link rel="nofollow" href={href} aria-label={label} className="group flex rounded-full px-3 hover:bg-black py-2 gap-2 relative w-12 xl:hover:w-40 transition-all" target="_blank">
              <Icon className="h-6 w-6 group-hover:fill-white" />
              <div className="hidden xl:group-hover:inline-block !text-white absolute top-2.5 right-full w-36 h-full flex items-center pr-2 transition-all ease-in-out delay-150 main-text text-sm group-hover:-right-7">
                View on Github
                </div>
            </Link>

          </div>
        );
      })}
    </>
  );
};