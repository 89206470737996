import Link from 'next/link'
import Image from 'next/image'
import Script from 'next/script'
import { useEffect } from 'react'
import { loadScripts } from '@/lib/loadNewsletterScripts'
export interface AuthorConfig {
  name: string
  url: string
  logo: string
}

interface FooterLinkSubItem {
  name: string
  href: string
}

export interface FooterLink {
  name: string
  subItems: Array<FooterLinkSubItem>
}

interface Props {
  links: Array<FooterLink>
  author: AuthorConfig
  newsletter: boolean
}

export const Footer: React.FC<Props> = ({ links, author, newsletter }) => {
  useEffect(() => {
    /*
     * The newsletter scripts MUST be loaded after
     * the document is loaded, as they depend on
     * the presence of some elements
     *
     */
    if (document.readyState === 'complete') {
      const { resetElements } = loadScripts()

      return () => {
        resetElements()
      }
    } else {
      window.addEventListener('load', loadScripts)
      return () => window.removeEventListener('load', loadScripts)
    }
  }, [])
  return (
    <footer className="font-['Inter']  prose dark:prose-invert max-w-none flex flex-col items-center justify-center w-full h-auto pt-0 pb-20 pt-12 ">
      <link
        rel="stylesheet"
        href="https://sibforms.com/forms/end-form/build/sib-styles.css"
      />
      <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 w-full max-w-8xl px-4 justify-center  footer-grid">
        {links.map((item) => (
          <li
            key={item.name}
            className="flex flex-col items-center sm:items-start"
          >
            <h3 className="inline-flex items-center mx-4 px-1 pt-1 small-title">
              {item.name}
            </h3>
            {item.subItems.map((subItem) => (
              <Link
                key={subItem.href}
                href={subItem.href}
                target="_blank"
                className="inline-flex items-center mx-4 px-1 pt-1 main-text no-underline !text-black text-center sm:text-start"
              >
                {subItem.name}
              </Link>
            ))}
          </li>
        ))}
        <li className="sib-form list-none !font-['Inter'] !p-0">
          <div
            id="sib-form-container"
            className="w-full flex flex-col items-center sm:items-start !p-0 !m-0"
          >
            <h3 className="inline-flex sm:text-left small-title px-1 pt-1">
              Newsletter
            </h3>
            <form
              id="sib-form"
              method="POST"
              action="https://0613d040.sibforms.com/serve/MUIEAKfRo-lO46VJlQJ-1kVozCMQ0S_5S2gdVtBTlUlEjP20NVpOEB51v1hXi3E3WbALTojbhLXtePha3kA3eY7oOtyVCc9pbGx5EG4-GTBhbCZUiOgOqM7oZl5l9JoL77yVQD168dfbpoMFpOcLnjrFeWfTTUU2SOdw2Pa6IDib71CTkWsBIKW3LjqOqDfQ4rxxIZ4g4BCRoaq8"
              data-type="subscription"
              className="flex flex-col items-center sm:items-start px-1 pt-1"
            >
              {newsletter ? (
                ''
              ) : (
                <div className=" flex flex-col">
                  <div className="sib-input sib-form-block !p-0 min-w-[8rem] ">
                    <div className="form__entry entry_block">
                      <div className="entry__field w-full">
                        <input
                          className="input !px-4 !text-black w-full placeholder:!text-gray-400 placeholder:!text-sm "
                          maxLength={200}
                          type="text"
                          id="FIRSTNAME"
                          name="FIRSTNAME"
                          placeholder="Your name"
                          autoComplete="off"
                          data-required="true"
                          title="first name"
                          required
                        />
                      </div>
                      <label className="entry__error entry__error--primary !max-w-full !text-sm text-left bg-[#ffeded] border-[#ff4949] rounded-md"></label>
                    </div>
                  </div>
                  <div className="sib-input sib-form-block !p-0 w-full ">
                    <div className="form__entry entry_block">
                      <div className="entry__field w-full">
                        <input
                          className="input !text-black !px-4 w-full placeholder:!text-gray-400 placeholder:!text-sm "
                          type="text"
                          id="EMAIL"
                          name="EMAIL"
                          placeholder="Your email address"
                          autoComplete="off"
                          data-required="true"
                          title="Email"
                          required
                        />
                      </div>
                      <label className="entry__error entry__error--primary !max-w-full !text-sm text-left bg-[#ffeded] border-[#ff4949] rounded-md"></label>
                    </div>
                  </div>
                </div>
              )}
              {/* recaptcha */}
              <div
                className="g-recaptcha !w-0"
                data-sitekey="6LfnC1IkAAAAAF2e32Omr3Vh-_byMLrtYhpv98pm"
                data-callback="invisibleCaptchaCallback"
                data-size="invisible"
                // @ts-ignore
                onClick={(e) => this.executeCaptcha(e)}
                tabIndex={0}
              />
              {newsletter ? (
                <>
                  <Link
                    className=" relative w-full sm:!m-0 btn-primary"
                    tabIndex={0}
                    href="/newsletter"
                  >
                    <svg
                      className="icon clickable__icon progress-indicator__icon sib-hide-loader-icon absolute left-1/3 !m-0 h-6"
                      viewBox="0 0 512 512"
                    >
                      <path d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
                    </svg>
                    Subscribe
                  </Link>
                  <input
                    type="text"
                    name="email_address_check"
                    defaultValue=""
                    className="hidden"
                    aria-hidden={true}
                  />
                  <input
                    type="hidden"
                    name="locale"
                    defaultValue="en"
                    aria-hidden={true}
                  />
                </>
              ) : (
                <>
                  <button
                    className="sib-form-block__button sib-form-block__button-with-loader relative w-full sm:!m-0 btn-primary"
                    form="sib-form"
                    type="submit"
                    aria-label="subscribe"
                    tabIndex={0}
                  >
                    <svg
                      className="icon clickable__icon progress-indicator__icon sib-hide-loader-icon absolute left-1/3 !m-0 h-6"
                      viewBox="0 0 512 512"
                    >
                      <path d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
                    </svg>
                    Subscribe
                  </button>
                  <input
                    type="text"
                    name="email_address_check"
                    defaultValue=""
                    className="hidden"
                    aria-hidden={true}
                  />
                  <input
                    type="hidden"
                    name="locale"
                    defaultValue="en"
                    aria-hidden={true}
                  />
                </>
              )}
            </form>
            <div
              id="error-message"
              className="sib-form-message-panel !w-fit !m-0 !mt-4 text-base text-left text-[#661d1d] bg-[#ffeded] border border-[#ff4949] rounded"
            >
              <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
                <svg
                  viewBox="0 0 512 512"
                  className="sib-icon sib-notification__icon"
                >
                  <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z" />
                </svg>
                <span className="sib-form-message-panel__inner-text">
                  Your subscription could not be saved. Please try again.
                </span>
              </div>
            </div>
            <div
              id="success-message"
              className="sib-form-message-panel !w-fit !m-0 !mt-4 text-base text-left text-[#085229] bg-[#e7faf0] border border-[#13ce66] rounded"
            >
              <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
                <svg
                  viewBox="0 0 512 512"
                  className="sib-icon sib-notification__icon"
                >
                  <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z" />
                </svg>
                <span className="sib-form-message-panel__inner-text">
                  Your subscription has been successful!
                </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div className="flex items-center justify-center mt-16 bg-transparent">
        Made with
        <a
          href="https://flowershow.app/"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center no-underline"
          title="Flowershow"
        >
          <Image
            src="/assets/images/logos/Flowershow-Logo.svg"
            alt="Flowershow"
            className="my-0 h-6 block w-6"
            width="32"
            height="32"
          />
          Flowershow
        </a>
      </div>
      <div className="flex items-center justify-center mt-4">
        Created by
        <a
          href={author.url}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center object-center no-underline pl-1 "
          title={author.name}
        >
          {author.logo && (
            <Image
              src={author.logo}
              alt={author.name}
              width="32"
              height="32"
              className="my-0 block m-1 bypass-filter aspect-[1/1]"
            />
          )}
          <div className="px-1.5">{author.name}</div>
        </a>
      </div>
      <div className="flex gap-2 items-center mt-4">
        <a
          href={`https://www.twitter.com/datopian`}
          title="twitter"
          aria-describedby="df"
          target="_blank"
        >
          <div
            className="hover:bg-orange-400 hover:cursor-pointer w-8 h-8  rounded-lg flex items-center justify-center"
            id="df"
          >
            <Image
              className="bypass-filter h-6 w-6 pt-1"
              height="16"
              width="16"
              src="/assets/images/logos/social-logo-twt-black.svg"
              alt="Twitter"
            />
          </div>
        </a>
        <a href={`https://www.linkedin.com/company/datopian`} title="linkedin" target="_blank">
          <div className="hover:bg-orange-400 hover:cursor-pointer w-8 h-8  rounded-lg flex items-center justify-center">
            <Image
              className="bypass-filter h-7 w-6"
              height="16"
              width="16"
              alt="Linkedin"
              src="/assets/images/logos/social-logo-lin-black.svg"
            />
          </div>
        </a>
        <a href={`https://www.facebook.com/datopianltd`} title="facebook" target="_blank">
          <div className="hover:bg-orange-400 hover:cursor-pointer w-8 h-8  rounded-lg flex items-center justify-center">
            <Image
              className="bypass-filter w-3.5 pt-0.5"
              height="8"
              width="10"
              alt="Facebook"
              src="/assets/images/logos/social-logo-fb-black.svg"
            />
          </div>
        </a>
        <a
          href={`https://www.upwork.com/agencies/~0132fb03ab1a8afecc`}
          title="upwork"
          target="_blank"
        >
          <div className="hover:bg-orange-400 hover:cursor-pointer w-8 h-8  rounded-lg flex items-center justify-center">
            <Image
              className="bypass-filter w-8 h-5.5 mt-10"
              height="16"
              width="20"
              alt="Upwork"
              src="/assets/images/logos/social-logo-upwork-black.webp"
            />
          </div>
        </a>
      </div>
      <div className="h-auto">
        <Link href="https://ckan.org" target="_blank">
          <img src="/assets/images/logos/ckan-costeward.svg" width={180}></img>
        </Link>
      </div>
    </footer>
  )
}
